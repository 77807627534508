import React from "react"

import {
  Layout,
  About,
  SplashScreen,
  RecentBlogPosts,
  Services,
  Testimonials,
  Transformations,
  Seo,
} from "../components"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const { frontmatter: content } = data.content

  return (
    <Layout>
      <Seo title="Strona główna" />

      <div className="active-dark">
        {/* <Helmet pageTitle="Main Demo" /> */}
        <div className="slider-wrapper">
          <SplashScreen
            title={content.header.title}
            subtitle={content.header.subtitle}
            image={content.header.image}
          />
        </div>

        <div className="about-area about-position-top pb--120 bg_color--1">
          <About
            title={content.about.title}
            content={content.about.content}
            image={content.about.image}
            left={content.about.left}
            right={content.about.right}
            ctaButtonText={content.about.ctaButton}
          />
        </div>

        <div className="portfolio-area pb--120 pt--50">
          <div className="section-title text-center">
            <h2 className="portfolio-area__title title">
              {content.transformations.title}
            </h2>
          </div>

          <div className="row">
            <div className="col-12">
              <Transformations items={content.transformations.list} />
            </div>
          </div>
        </div>

        <div className="portfolio-area pb--120 pt--50 bg_color--1">
          <div className="section-title text-center">
            <h2 className="portfolio-area__title title">
              {content.testimonials.title}
            </h2>
            <p className="pb--30">{content.testimonials.subtitle}</p>
          </div>

          <div className="row">
            <div className="col-12">
              <Testimonials testimonials={data.testimonials.edges} />
            </div>
          </div>
        </div>

        <div className="service-area pt--50 pb--80">
          <div className="container">
            <Services
              image={content.offer.image}
              title={content.offer.title}
              content={content.offer.content}
              services={data.offer.frontmatter.services}
            />
          </div>
        </div>

        <RecentBlogPosts
          title={content.blog.title}
          content={content.blog.content}
          posts={data.blogPosts.edges}
        />
      </div>
    </Layout>
  )
}

export const guery = graphql`
  {
    content: markdownRemark(
      fields: { collection: { eq: "pages" }, slug: { eq: "home" } }
    ) {
      frontmatter {
        header {
          title
          subtitle
          image
        }
        about {
          title
          content
          image
          left {
            title
            content
          }
          right {
            title
            content
          }
          ctaButton
        }
        transformations {
          title
          list {
            image
          }
        }
        testimonials {
          title
          subtitle
        }
        offer {
          title
          content
          image
        }
        blog {
          title
          content
        }
      }
    }

    blogPosts: allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            thumbnail
            category
          }
          fields {
            slug
          }
        }
      }
    }

    testimonials: allMarkdownRemark(
      filter: { fields: { collection: { eq: "testimonials" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image
            fullName
            content
          }
        }
      }
    }

    offer: markdownRemark(
      fields: { collection: { eq: "pages" }, slug: { eq: "offer" } }
    ) {
      frontmatter {
        services {
          title
          homePage {
            icon
            description
          }
        }
      }
    }
  }
`

export default IndexPage
